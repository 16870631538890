import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import {useCallback, useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import Form from './Form';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { TextPlugin } from 'gsap/TextPlugin';
import { CSSPlugin } from 'gsap/CSSPlugin';
import '../App.css';
import Spline from '@splinetool/react-spline';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';



gsap.registerPlugin(TextPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CSSPlugin);

export default function Home() {

  const { t } = useTranslation();
  


  const scrollToSection = (sectionClass) => {
    const sectionElement = document.querySelector(sectionClass);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  const [showTextServices1, setShowTextServices1] = useState(false);
  const [showTextServices2, setShowTextServices2] = useState(false);
  const [showTextServices3, setShowTextServices3] = useState(false);
  const [showForm, setshowForm] = useState(false);
  const [showHeader, setshowHeader] = useState(true);
  const [showFooter, setshowFooter] = useState(true);
  
  useEffect(() => {
    // GSAP animation
    
    gsap.from(".title-2", { text: "", });
    gsap.to('.title-2', {
      opacity: 1,
      color: "#9A9898",
      text: t("home.whoAmI"),
      scrollTrigger: {
        trigger: '.title-2', 
        start: 'top center', 
        ease: "power1.inOut",
      },
    });

    gsap.from(".title-3", { text: "", });
    gsap.to('.title-3', {
      opacity: 1,
      text: t("home.projects"),
      scrollTrigger: {
        trigger: '.title-3', 
        start: 'center center', 
        ease: "power1.inOut",
      },
    });

    gsap.from(".title-s", {text: "", });
    gsap.to('.title-s', {
      opacity: 1,
      text: t("home.services"),
      scrollTrigger: {
        trigger: '.title-s', 
        start: 'center center', 
        ease: "power1.inOut",
      },
    });

    gsap.set(".webs", { y: 60, opacity: 0 });
    gsap.to('.webs', {
      opacity: 1,
      y: 0,
      scrollTrigger: {
        trigger: '.webs', 
        start: 'top center', 
        end: 'bottom center', 
        ease: "none",
        once: true 
      },
    });

    gsap.set(".line", { width: 0, opacity: 0 });
    gsap.to('.line', {
      duration: 1.5,
      opacity: 1,
      width: '99%',
      scrollTrigger: {
        trigger: '.webs', 
        start: 'top center', 
        end: 'bottom center', 
      },
    });

    gsap.from(".img-xpr", { width: '63%'});
    gsap.to('.img-xpr', {
      width: '100%',
      scrollTrigger: {
        trigger: '.title-3', 
        start: 'top center', 
        scrub: true,
      },
    });

        
    
    
    

    gsap.set(".HomeBox2", { background: '#f1f1f1'});
    gsap.to('.HomeBox2', {
      background: '#1e1e1e',
      duration: 1,
      scrollTrigger: {
        trigger: '.HomeBox2', 
        start: 'top center', 
        end: 'bottom center',
      },
    });

    
      
    
  gsap.set(".text-description", { y: 60, opacity: 0,});
  gsap.to('.text-description', {
    duration: 0.4,
    opacity: 1,
    y: 0,
    ease: "none",
    scrollTrigger: {
      trigger: '.text-description', 
      start: 'top 60%', 
      end: 'bottom center', 
      once: true,
    },
  });

 
  ScrollTrigger.create({
    trigger: '#skill1',
    start: 'top center',
    end: 'bottom top',
    onEnter: () => gsap.to('#skill1', { background: '#1e1e1e', color: "white", duration: 0.1, ease: "power1.out" }),
    onLeave: () => gsap.to('#skill1', { background: '', color: "black", duration: 0.1, ease: "power1.out" }),
    onEnterBack: () => gsap.to('#skill1', { background: '#1e1e1e', color: "white", duration: 0.1, ease: "power1.out" }),
    onLeaveBack: () => gsap.to('#skill1', { background: '', color: "black", duration: 0.1, ease: "power1.out" }),
    scrub: true
  });

  ScrollTrigger.create({
    trigger: '#skill2',
    start: 'top center',
    end: 'bottom top',
    onEnter: () => gsap.to('#skill2', { background: '#1e1e1e', color: "white", duration: 0.1, ease: "power1.out" }),
    onLeave: () => gsap.to('#skill2', { background: '', color: "black", duration: 0.1, ease: "power1.out" }),
    onEnterBack: () => gsap.to('#skill2', { background: '#1e1e1e', color: "white", duration: 0.1, ease: "power1.out" }),
    onLeaveBack: () => gsap.to('#skill2', { background: '', color: "black", duration: 0.1, ease: "power1.out" }),
    scrub: true
  });

  ScrollTrigger.create({
    trigger: '#skill3',
    start: 'top center',
    end: 'bottom top',
    onEnter: () => gsap.to('#skill3', { background: '#1e1e1e', color: "white", duration: 0.1, ease: "power1.out" }),
    onLeave: () => gsap.to('#skill3', { background: '', color: "black", duration: 0.1, ease: "power1.out" }),
    onEnterBack: () => gsap.to('#skill3', { background: '#1e1e1e', color: "white", duration: 0.1, ease: "power1.out" }),
    onLeaveBack: () => gsap.to('#skill3', { background: '', color: "black", duration: 0.1, ease: "power1.out" }),
    scrub: true
  });

  


  
  let lt = gsap.timeline({repeat: 0,})

  gsap.set('.sub-title', {
    y: 10,
  });

  gsap.set('.title', {
    y: 10,
  });

  lt.to('.sub-title', {
    duration: 0.8,
    y: 0,
    opacity: 1,
    ease: "power1.inOut",
  });
      
  lt.to('.title', {
    duration: 0.8,
    y: 0,
    opacity: 1,
    color: "#3F3F3F",
    ease: "none",
    });
    


  gsap.fromTo('.description', 
  { text: "", opacity: 0 },
  {
    delay: 0.8,
    duration: 2,
    text: t("home.description"),
    opacity: 1,
    ease: "power1.inOut",
    onComplete: function() {
      gsap.to('.description', {
        color: "#3F3F3F",
      });
    }
  }
  );

    

    gsap.to('.ul', {
      duration: 1,
      borderBottomWidth: '2px',
      borderBottomColor: '#000',
      borderBottomStyle: 'solid',
      scrollTrigger: {
        trigger: '.ul', 
        start: 'top center', 
        end: 'bottom bottom', 
        scrub: true,
        ease: "power1.out",
      },
    });

  gsap.to('.vertical-line', {
    height: '26vh',
    scrollTrigger: {
      trigger: '.vertical-line',
      start: 'top center',
      scrub: true,
      onUpdate: self => {
        if (self.progress === 1) {
          gsap.to('#proyecto', {
            opacity: 1,
            duration: 1
          });
        }
        else{
          gsap.to('#proyecto', {
            opacity: 0,
            duration: 1
          });
        }
      }
    }
  });

  gsap.to('.vertical-line1', {
    height: '20vh',
    scrollTrigger: {
      trigger: '.proyecto',
      start: 'top center',
      scrub: true,
    }
  });

  gsap.to('.dark-hover-project', {
    duration: 0.4,
    opacity: 0.8,
    scale: 1.2,
    scrollTrigger: {
      trigger: '.slider', 
      start: 'top center', 
      end: 'bottom center', 
      scrub: true,
      ease: "power1.out",
    },
  });

  gsap.to('.visual-img1', {
    duration: 1.6,
    position: 'static',
    scrollTrigger: {
      trigger: '.visual-img1',
      start: 'center center',
      scrub: true,
      onUpdate: function(self) {
        const progress = self.progress;
        if (progress < 0.5) {
          gsap.to('.visual-img1', { duration: 1.5, ease: 'power2.out' , marginLeft: 'auto', marginRight: 'auto' });
        } else {
          gsap.to('.visual-img1', { duration: 1.5, ease: 'power2.out' , marginLeft: '0', marginRight: '0' });
        }
      }
    }
  });

  

  
  }, []);

  const toggleTextServices1 = () => {
    setShowTextServices1(!showTextServices1);
    gsap.from('.text-services1', {
      duration: 1,
      y: -10,
    });
  };

  const toggleTextServices2 = () => {
    setShowTextServices2(!showTextServices2);
    gsap.from('.text-services2', {
      duration: 1,
      y: -10,
    });
  };

  const toggleTextServices3 = () => {
    setShowTextServices3(!showTextServices3);
    gsap.from('.text-services3', {
      duration: 1,
      y: -10,
    });
  };

  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const handleContactButtonClick = () => {
    gsap.to('.black-screen',{
      duration: 1,
      display: 'block',
      width: '100%',
      onComplete: function(){
        setshowForm(true);
        setshowHeader(false);
        setshowFooter(false);
        const hidePage = document.querySelector('.ContainerPage');
        hidePage.style.display = 'none';
        gsap.set('.black-screen',{delay: 0.2, width: '0px', display: 'none'})
      }
    });
    
  };

  const reload = () => {
    setshowForm(false);
    setshowHeader(true);
    setshowFooter(true);
    const hidePage = document.querySelector('.ContainerPage');
    hidePage.style.display = 'block';
    gsap.to('.black-screen',{
      duration: 1, delay: 1, width: '0px', display: 'none'});
      };

      
  return (
    <div>
      <Helmet>
        <title>{t('home.titleseo')}</title>
        <meta name="description" content={t('home.descseo')} />
        <meta name="keywords" content={t('home.keyseo')} />
      </Helmet>
      {showHeader && <Header scrollToSection={scrollToSection} onContactButtonClick={handleContactButtonClick}  />}
      {showForm && <Form toHome={reload} />} 
      <div className='ContainerPage'>
      <div className='HomeBox1'>
    <p className='sub-title'>{t('home.greeting')}</p>
    <h2 className='title'>{t('home.name')}</h2>

    <section className='circle'>
      <Spline scene="https://prod.spline.design/WzQjtammj5C9eopW/scene.splinecode" />
    </section>

    <h1 className='description'>{t('home.description')}</h1>
</div>

<div className='socials'>
    <a onClick={() => window.location.href="https://www.instagram.com/visual.gio/"}><p className='social-cta'>{t("Instagram")}<img className='arrow' src='arrow.png' alt='black arrow'/></p></a>
    <a onClick={() => window.location.href="https://www.linkedin.com/in/gianluca-giordano-78a27a245/"}><p className='social-cta'>{t("LinkedIn")}<img className='arrow' src='arrow.png' alt='black arrow'/></p></a>
    <a onClick={() => window.location.href='mailto:info@gianlucagiordano.dev'}><p className='social-cta'>{t("Email")}<img className='arrow' src='arrow.png' alt='black arrow'/></p></a>
</div>

<section className='HomeBox2'>
    <h2 className='title-2'>{t("home.whoAmI")}</h2>
    <p className='text-description'>{t("home.whoAmIText")} <br/><br/>{t("home.basedIn")}</p>
</section>

<div className='services'>
    <h2 className='title-s'>{t("home.services")}</h2>

    <section className='webs'>
      <h3 className='title-services'>{t("home.webDevelopment")}</h3>
      <button className="more" id='skill1' onClick={toggleTextServices1}>{showTextServices1 ? '-' : '+'}</button>
    </section>
    <p className={`text-services1 ${showTextServices1 ? 'show' : ''}`}>{t("home.webDevelopmentText")}</p>
    <hr className='line' id='line1'></hr>

    <section className='webs'>
      <h3 className='title-services'>{t("home.web3DDevelopment")}</h3>
      <button className="more" id='skill2' onClick={toggleTextServices2}>{showTextServices2 ? '-' : '+'}</button>
    </section>
    <p className={`text-services2 ${showTextServices2 ? 'show' : ''}`}>{t("home.web3DDevelopmentText")}</p>
    <hr className='line' id='line2'></hr>

    <section className='webs'>
      <h3 className='title-services'>{t("home.webDesign")}</h3>
      <button className="more" id='skill3' onClick={toggleTextServices3}>{showTextServices3 ? '-' : '+'}</button>
    </section>
    <p className={`text-services3 ${showTextServices3 ? 'show' : ''}`}>{t("home.webDesignText")}</p>
    <hr className='line' id='line3'></hr>
</div>

<marquee className='vertical-text'>{t("home.description")}</marquee>

<section className='HomeBox3'>
    <h2 className='title-3'>{t("home.projects")}</h2>
    <div className='section3d'>
      <div className='section1-3d'>
        <img className='img-xpr' src='xpr.png' alt={t('Restaurante 3d Gianluca Giordano')} />
      </div>
      <div className='section2-3d'>
        <h3 className='project-title'>{t("home.restaurant3D")}</h3>
        <button className='xpr' onClick={() => window.location.href="https://www.gianlucagiordano.dev/xpr"}>{t("home.visitRestaurant")}<img className='arrow' src='arrow.png' alt='black arrow'/></button>
        <p className='text-description2'>{t("home.restaurant3DText")}</p>
      </div>
    </div>
    <hr className='linea'/>
    <h3 className='project-title'>{t("home.jewelry")}</h3>
    <section className='visual-slider'>
      <img className='visual-img1' src='./visual-identity2.PNG' alt={t('Palette Visual Identity')}/>
      <img className='visual-img1' src='./visual-identity3.PNG' alt={t('Fonts Visual Identity')}/>
      <img className='visual-img1' src='./visual-identity4.PNG' alt={t('Revista Visual Identity')}/>
    </section>
    <p className='text-description2'>{t("home.visualIdentity")}</p>
    <div className="vertical-line"></div>
    <a onClick={() => window.location.href="https://www.gianlucagiordano.dev/veed"}><p className='text-description2' id='proyecto'><span className='ul'>{t("home.website")}</span><img className='arrow' src='arrow.png'/></p></a>
    <div className="vertical-line1"></div>
    <section className='conf-container'>
      <img className='conf-img' src='./3droom.gif' alt={t('Configurador 3D Anillo')}/>
    </section>
    <a onClick={() => window.location.href="https://www.gianlucagiordano.dev/veed/3d"}><p className='text-description2' id='proyecto'><span className='ul'>{t("home.3DConfigurator")}</span></p></a>
  </section>

  <section className='HomeBox4'>
    <h3 className='title-4'>{t("home.businessImprovement")}</h3>
    <p className='text-description2'>{t("home.businessImprovementText")}</p>
    <a onClick={() => window.location.href="https://www.gianlucagiordano.dev/como-sitio-web-distintivo-mejora-imagen-negocio-las-palmas-gran-canaria"}><p className='text-description2'><span className='ul'>{t("home.readMore")}</span></p></a>
  </section>
  </div>

    {showHeader && <Footer />}
    <div className='black-screen'></div>
    </div>
  );
}