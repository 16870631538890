import React from 'react'
import {useCallback, useEffect, useState } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import Footer from './Footer';
import Form from './Form';
import Spline from '@splinetool/react-spline';


const Configurator = () => {

    const { t } = useTranslation();

    const scrollToSection = (sectionClass) => {
        const sectionElement = document.querySelector(sectionClass);
        if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      };

      const [showForm, setshowForm] = useState(false);
      const [showHeader, setshowHeader] = useState(true);
      const [showFooter, setshowFooter] = useState(true);

      const handleContactButtonClick = () => {
        gsap.to('.black-screen',{
          duration: 1,
          display: 'block',
          width: '100%',
          onComplete: function(){
            setshowForm(true);
            setshowHeader(false);
            setshowFooter(false);
            const hidePage = document.querySelector('.ContainerPage');
            hidePage.style.display = 'none';
            gsap.set('.black-screen',{delay: 0.2, width: '0px', display: 'none'})
          }
        });
        
      };

      const reload = () => {
        setshowForm(false);
        setshowHeader(true);
        setshowFooter(true);
        const hidePage = document.querySelector('.ContainerPage');
        hidePage.style.display = 'block';
        gsap.to('.black-screen',{
          duration: 1, delay: 1, width: '0px', display: 'none'});
          };
    
  return (
    <div>
      <Helmet>
        <title>{t('home.titleseo')}</title>
        <meta name="description" content={t('home.descseo')} />
        <meta name="keywords" content={t('home.keyseo')} />
      </Helmet>
      {showHeader && <Header scrollToSection={scrollToSection} onContactButtonClick={handleContactButtonClick}  />}
            {showForm && <Form toHome={reload} />} 
      <div className="scene-container">
      <div className='container-landing'>
      <h1 className='title-ld'>{t("lp-3d.title-2")} <br></br><span className='small-text'>{t("lp-3d.small-title")} </span></h1>
      <img className='img-ld' src='3droom.gif'/>

      <p className='text-ld'>{t("lp-3d.subtitle")}</p>
      <section className='buttons-ld'>
        <button className='btn-ld' onClick={() => window.location.href="mailto: info@gianlucagiordano.dev"}>
          {t('home.navContact')}
        </button>
        
      </section>
      <section className='carousel'>
        <a onClick={() => window.location.href="https://www.gianlucagiordano.dev/xpr"}><section className='carousel-img1'><img className='content-img' src='/xpr.png'/></section></a>
        <a onClick={() => window.location.href="https://www.gianlucagiordano.dev/veed/3d"}><section className='carousel-img1'><img className='content-img' src='/showroom.png'/></section></a>
      </section>
      <h2 className='title-ld'>{t("lp-3d.title-3")} </h2>
      <p className='text-ld'>{t("lp-3d.text-desc")} </p>
      </div>
      {showHeader && <Footer />}
          <div className='black-screen'></div>
      
          
    </div>
    </div>
  )
}

export default Configurator
